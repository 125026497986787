export interface Config {
  vgs: { vaultId: string; environment: string; sdkVersion: string };
  checkout: {
    publicKey: string;
    apiUrl: string;
    applePayMerchantIdentifier: string;
    googlePayMerchantIdentifier: string;
  };
  sentry: { dsn: string };
  homepage: { url: string };
  actionCable: { uri: string };
  gateway: string;
  isProduction: boolean;
  isStaging: boolean;
  pciWidgetPath: string;
  sift: {
    beaconKey: string;
  };
  googleSso: {
    clientId: string;
  };
}

export const variables: Config = {
  gateway: "tap",
  vgs: {
    vaultId: process.env.REACT_VGS_VAULT_ID || "",
    environment: process.env.REACT_APP_ENV == "production" ? "live" : "sandbox",
    sdkVersion: process.env.REACT_VGS_SDK_VERSION || "2.24.6",
  },
  checkout: {
    publicKey: process.env.REACT_CHECKOUT_PUBLIC_KEY || "",
    apiUrl: process.env.REACT_CHECKOUT_API_URL || "",
    applePayMerchantIdentifier:
      process.env.REACT_CHECKOUT_APPLE_PAY_MERCHANT_IDENTIFIER || "merchant.com.mamobusiness.checkout.debug",
    googlePayMerchantIdentifier: process.env.REACT_GOOGLE_PAY_MERCHANT_IDENTIFIER || "",
  },
  sentry: {
    dsn: process.env.REACT_SENTRY_DSN || "",
  },
  homepage: {
    url: "https://mamopay.com/business",
  },
  actionCable: {
    uri: process.env.ACTION_CABLE_URI || "ws://localhost:3000/cable",
  },
  isProduction: process.env.REACT_APP_ENV == "production",
  isStaging: process.env.REACT_APP_ENV == "staging",
  pciWidgetPath:
    process.env.REACT_APP_ENV == "production"
      ? "https://showcardwidget.platform.ae-1.nymcard.com"
      : "https://showcardwidget.stg.platform.ae-1.nymcard.com",
  sift: {
    beaconKey: process.env.REACT_SIFT_BEACON_KEY || "",
  },
  googleSso: {
    clientId: process.env.REACT_GOOGLE_SSO_CLIENT_ID || "",
  },
};
